import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';  // Slick Carousel Styles
import 'slick-carousel/slick/slick-theme.css';  // Slick Carousel Theme
import $ from 'jquery';  // Import jQuery
import 'slick-carousel';  // Import Slick Carousel
import '../components/css/style.css';
import { Link } from 'react-router-dom';

function Login (){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);

    const handleLoginClick = () => {
        setIsActive(true);  // Add the 'active' class
    };

    const handleCloseClick = () => {
        setIsActive(false);  // Remove the 'active' class
    };

    // Slick Carousel Initialization after the component has mounted
    useEffect(() => {
        // Initialize Slick Carousel
        $('.testimonial-slider').slick({
            dots: true,   // Add dots for navigation
            infinite: true,  // Infinite loop
            speed: 500,  // Transition speed
            slidesToShow: 1,  // Only show one slide at a time
            slidesToScroll: 1,  // Scroll one slide at a time
            autoplay: true,  // Auto-play the carousel
            autoplaySpeed: 2000,  // Delay between slides
        });
    }, []);

    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Error:", error);
    console.log("Navigate function:", navigate);


    // Check if user is already logged in
    React.useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (loggedIn) {
            navigate('/dashboard');
        }
    }, [navigate]);
   
    // Handle login submission
    const handleLogin = async (e) => {
        e.preventDefault();
    
        // Log the email and password to ensure they are being passed correctly
        console.log("Email:", email);
        console.log("Password:", password);
    
        try {
            // Make the fetch request to the API
            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/custom_login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',  // Ensuring content type is set correctly
                },
                body: JSON.stringify({
                    email,  // Using the state values for email and password
                    password
                }),
            });
    
            const data = await response.json();
            console.log("Response Data:", data);  // Log the response data

            if (data.status) {
                // Set login status in local storage if login is successful
                localStorage.setItem('isLoggedIn', 'true');
                localStorage.setItem('user', JSON.stringify(data.data)); // Store the entire user data
                navigate('/dashboard'); // Redirect to dashboard
            } else {
                setError('Invalid login credentials');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
            console.error('Error during login:', error);  // Log any errors
        }
    };
    

    return (
    <>
    

<div className="topbar-head">
    <div className="container">
        <div className="topbar-wrapper">
            <div className="contact-info">
                <a href="tel:876-831-1387">
                    <i className="fa-brands fa-whatsapp"></i>
                    876-831-1387
                </a>
                <a href="tel:876-831-1387">
                    <i className="fa-regular fa-envelope"></i>
                    info@extendeddiscsolutions.org
                </a>
            </div>
            <ul className="list-unstyled socials-icons d-flex gap-3 m-0">
                <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
            </ul>
        </div>
    </div>
</div>

<header className="header">
    <nav className="navbar navbar-expand-lg">
        <div className="container">
            <Link className="navbar-brand" to="https://www.extendeddiscsolutions.org/">
                <img src="https://www.extendeddiscsolutions.org/wp-content/uploads/logo/logo-orange.png" alt="Logo" className="img-fluid" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto gap-3">
                    <li className="nav-item">
                        <a id="login" onClick={handleLoginClick} className="button" href="#">Login</a>
                    </li>
                    <li className="nav-item">
                        <a className="button button-gradient" href="#">Signup</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>

<section className="hero-section">
    <div className="hero-image-box">
        <video className="background-video-hosted" autoplay="" muted="" playsinline="" loop="" src="https://extendeddiscsolutions.org/wp-content/uploads/2022/10/pexels-diva-plavalaguna-6985519-1.mp4"></video>
    </div>
    <div className="new-app-logo"><img src="https://extendeddiscsolutions.org/wp-content/themes/astra-child/images/splash-robot.png " alt="app logo" /></div>
    <div className="hero-content-wrapper">
        <div className="container">
            <div className="hero-content-inner">
                <span className="sub-title">Cultivate Excellence with AI-Enhanced Behavioural Assessments</span>
                <h1 className="hero-title">Redefining Recruitment & Selection, Leadership, and <span className="text-orange">Sales Success</span></h1>
                <p>Experience a groundbreaking solution that revolutionizes the way you approach recruitment,
                    leadership
                    development, and sales capacity building. Our AI-enhanced Behavioural Assessments unlock
                    unparalleled insights into individuals' behaviour, enabling smarter hiring decisions,
                    personalized
                    leadership growth, and optimized sales strategies.</p>
                    <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                        <button className="button button-gradient">Request a Quote</button>
                    </Link>
            </div>
        </div>
    </div>
</section>

<section className="behavioural-section bg-gray-light">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-md-6">
                <img src="https://extendeddiscsolutions.org/wp-content/uploads/react_images/image_1.jpg" alt="image_1" className="img-fluid rounded" />
            </div>
            <div className="col-md-6">
                <div className="behavioural-content">
                    <h2>Deep Dive into <span className="text-orange">Behavioural Competencies</span></h2>
                    <p>Embrace the future of professional excellence with our transformative approach, tailored to
                        reshape the landscape of recruitment, leadership, and sales effectiveness.</p>
                    <p>With a library of over 1,500 categorized competencies, you’ll receive very personalized and
                        specific results for each candidate/employee. Our cutting-edge technology leverages advanced
                        artificial intelligence to analyze your behavioural data and reveal patterns that may have
                        been previously unnoticed.</p>
                    <p>By delving deep into behavioural data, we empower businesses to discover hidden talents,
                        nurture exceptional leaders and elevate sales teams, driving unprecedented success and
                        growth.</p>
                    <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                    <button className="button">GET STARTED</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="recruitment-section">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="recruitment-card">
                    <h2>AI-Enhanced <span className="text-orange">Recruitment</span> & Selection</h2>
                    <ul className="list-unstyled recruitment-list">
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Redefine your hiring strategy with our AI-powered Behavioral Assessments.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Uncover candidates’ true potential, beyond what resumes reveal.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Our assessments provide deep insights into candidates’ behaviours, enabling you to
                                make data-driven, informed hiring decisions.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Additionally, you will receive personalized, behaviour-specific interview questions
                                for each candidate.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Elevate your interviews by focusing on meaningful conversations that uncover traits
                                crucial for success within your organization.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Eliminate bad hires with our AI-Enhanced screening insights!
                            </span>
                        </li>
                    </ul>
                    <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                    <button className="button">GET STARTED</button>
                    </Link>
                </div>
                <div className="recruitment-card card-bg-orange">
                    <h2>AI-Enhanced <span className="text-orange">Leadership</span> Development</h2>
                    <ul className="list-unstyled recruitment-list">
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Elevate your leadership to unprecedented heights with our AI-enhanced Behavioural
                                Assessments, meticulously designed to ignite transformative growth.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Discover your inherent leadership strengths, uncover hidden talents, and address
                                blind spots that might hinder your progress.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Our innovative solution not only provides personalized insights but also crafts a
                                bespoke roadmap for your leadership journey.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                With continuous feedback and tailored strategies, our platform becomes your
                                personalized leadership coach, propelling you towards influential leadership and
                                unparalleled success.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Embrace a future where your leadership potential knows no bounds.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Access AI-Enhanced coaching 24/7/365!
                            </span>
                        </li>
                    </ul>
                    <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                    <button className="button">GET STARTED</button>
                    </Link>
                </div>
                <div className="recruitment-card card-bg-purple">
                    <h2>AI-Enhanced <span className="text-orange">Sales Capacity</span> Development</h2>
                    <ul className="list-unstyled recruitment-list">
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Supercharge your sales prowess with our cutting-edge AI-enhanced Behavioural
                                Assessments, meticulously crafted to unleash your sales team's full potential.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Dive deep into the behaviours that drive successful salespeople, identifying
                                strengths and areas for growth unique to each individual.
                            </span>
                        </li>
                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Armed with these profound insights, we don't just stop there - we empower you with
                                personalized strategies and targeted coaching, transforming your sales approach into
                                an unstoppable force.
                            </span>
                        </li>

                        <li>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                className="check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16">
                                </path>
                                <path
                                    d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05">
                                </path>
                            </svg>
                            <span>
                                Close more sales with AI-Enhanced identification and removal of hidden limiting
                                factors!
                            </span>
                        </li>
                    </ul>
                    <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                    <button className="button">GET STARTED</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="difference-section bg-gray-light">
    <div className="container">
        <h2 className="text-center mb-4">Deepen the Extended DISC® difference with <br /><span
                className="text-orange">interactive AI-driven</span> reporting!</h2>
        <div className="row">
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Unlock the full potential of your behavioral assessment</h5>
                    <p>Experience a transformative journey of self-discovery and growth through our AI-driven,
                        iterative approach that takes your behavioral assessment to new heights.</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Personalized recommendations for continuous improvement</h5>
                    <p>Receive dynamic and personalized recommendations from our AI platform that guide you in
                        developing specific leadership competencies, enabling you to make meaningful progress over
                        time.</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Elevate your insights with AI-powered analysis</h5>
                    <p>Harness the power of AI to delve deeper into your behavioral assessment results, uncover
                        hidden patterns, and gain a comprehensive understanding of your unique leadership style.</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Embrace the future of behavioral assessments</h5>
                    <p>Stay ahead of the curve by embracing the innovative combination of AI technology and
                        behavioral assessments, opening new possibilities for self-awareness, growth, and leadership
                        effectiveness.</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Interactive learning for ongoing development</h5>
                    <p>Engage in an interactive and iterative process with our AI platform, where you can explore
                        different scenarios, receive targeted feedback, and continually refine your leadership
                        approach.</p>
                </div>
            </div>
            <div className="col-md-4">
                <div className="box-card">
                    <span className="box-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="29" fill="none">
                            <path fill="currentColor"
                                d="M13.002 0C8.852 0 5.46 3.387 5.46 7.53c0 4.143 3.392 7.53 7.542 7.53s7.542-3.387 7.542-7.53c0-4.143-3.393-7.53-7.542-7.53ZM25.944 21.076a7.017 7.017 0 0 0-.757-1.38c-1.515-2.236-3.853-3.716-6.488-4.078-.329-.033-.691.033-.955.23a7.942 7.942 0 0 1-4.742 1.546 7.942 7.942 0 0 1-4.742-1.545 1.237 1.237 0 0 0-.955-.23 9.308 9.308 0 0 0-6.488 4.076c-.296.428-.56.921-.757 1.381-.1.198-.066.428.033.625.263.46.592.92.889 1.315.46.625.955 1.184 1.515 1.71.46.46.988.888 1.515 1.315a14.936 14.936 0 0 0 8.957 2.96c3.228 0 6.356-1.02 8.958-2.96a14.47 14.47 0 0 0 1.515-1.315c.526-.526 1.053-1.085 1.515-1.71.329-.427.625-.854.889-1.315.164-.197.197-.427.098-.625Z">
                            </path>
                        </svg>
                    </span>
                    <h5>Unleash your leadership potential with AI-driven insights</h5>
                    <p>Leverage the advanced analytical capabilities of our AI platform to gain valuable insights,
                        challenge assumptions, and unlock new possibilities for leadership excellence.</p>
                </div>
            </div>
        </div>
        <div className="text-center">
        <Link to="https://www.extendeddiscsolutions.org/contact-us/">
            <button className="button">GET STARTED</button>
        </Link>
        </div>
    </div>
</section>

<section className="reports-address-sec">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h2>Extended <span className="text-orange">DISC® vs. DISC</span></h2>
                <p>Extended DISC® provides insights into both the conscious and unconscious domains. The reports
                    address the individual’s natural style and the perceived need To adjust.</p>
                <div className="button-wrapper mb-4">
                <Link to="https://www.extendeddiscsolutions.org/contact-us/">
                    <button className="button">GET STARTED</button>
                </Link>
                </div>
            </div>
            <div className="col-md-6">
                <div className="table-responsive">
                    <table className="table border">
                        <thead>
                            <tr>
                                <th scope="col">Behavior</th>
                                <th scope="col">Disc</th>
                                <th scope="col">Extended DISC®</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: '#EEEEEE', fontWeight: 'bold' }} colSpan="3">
                                  Conscious
                                </td>

                            </tr>
                            <tr>
                                <td>Work role</td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Desired behavior</td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Corporate culture</td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Adjusted behavior</td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>More energy</td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-md-6">
                <div className="table-responsive">
                    <table className="table border">
                        <thead>
                            <tr>
                                <th scope="col">Behavior</th>
                                <th scope="col">Disc</th>
                                <th scope="col">Extended DISC®</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ backgroundColor: '#EEEEEE', fontWeight: 'bold' }} colSpan="3">
                              Unconscious
                            </td>

                            </tr>
                            <tr>
                                <td>Spontaneous behavior</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Pressure behavior</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Natural behavior</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Less stressful behavior</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                            <tr>
                                <td>Less energy</td>
                                <td>
                                    &nbsp;
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="check-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"></path>
                                        <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"></path>
                                    </svg>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="benefits-section bg-gray-light">
    <div className="container">
        <div className="row">
            <div className="col-md-6 col-lg-4">
                <h2><span className="text-orange">Benefits</span></h2>
                <p>Revolutionize Your Path to Success: Unlock the Power of AI-Enhanced Behavioural Assessments Across Recruitment, Leadership, and Sales</p><Link to="https://www.extendeddiscsolutions.org/contact-us/"><button className="button">GET STARTED</button></Link>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="box-card">
                    <span className="h4 text-orange">01</span>
                    <h5>Precision in Hiring</h5>
                    <p>AI-driven assessments reveal candidates’ true potential, aiding smarter hiring choices and ensuring cultural fit for your organization.</p>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="box-card">
                    <span className="h4 text-orange">02</span>
                    <h5>Enhanced Self-Discovery</h5>
                    <p>Gain profound insights into your leadership style, pinpointing hidden strengths and areas of improvement, empowering your journey to influential leadership.</p>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="box-card">
                    <span className="h4 text-orange">03</span>
                    <h5>Optimize Sales Excellence</h5>
                    <p>Identify unique sales strengths within your team, transforming interactions into opportunities; AI insights enable targeted coaching for unparalleled sales effectiveness.</p>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="box-card">
                    <span className="h4 text-orange">04</span>
                    <h5>Customized Growth Roadmaps</h5>
                    <p>Tailor leadership and sales strategies to focus on areas with maximum impact, fostering continuous improvement.</p>
                </div>
            </div>
            <div className="col-md-6 col-lg-4">
                <div className="box-card">
                    <span className="h4 text-orange">05</span>
                    <h5>Continuous Evolution</h5>
                    <p>Leverage AI-powered feedback loops, offering dynamic learning experiences in recruitment, leadership, and sales, ensuring your team stays ahead in a rapidly changing landscape.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="contact-us-section">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <div className="contact-us-cont">
                    <h2><span className="text-orange">Contact</span> Us</h2>
                    <p>Start your transformative leadership journey today! Visit our website or contact our team to learn more about how our AI-powered platform can enhance your behavioral assessment experience and unlock your full leadership potential.</p>

                    <div className="quick-contact-wrapper d-flex gap-3 flex-column">
                        <a href="mailto:info@extendeddiscsolutions.org" className="quick-contact-card d-flex gap-3">
                           <img src="https://extendeddiscsolutions.org/wp-content/uploads/react_images/email.svg" loading="lazy" alt="" />
                           <div className="quick-contact-card-content">
                              <div className="fs-6 fw-semibold">Email us</div>
                              <div className="email-text">info@extendeddiscsolutions.org</div>
                           </div>
                        </a>
                        <a href="tel:876-831-1387" className="quick-contact-card d-flex gap-3">
                           <img src="https://extendeddiscsolutions.org/wp-content/uploads/react_images/call.svg" loading="lazy" alt="" />
                           <div className="quick-contact-card-content">
                              <div className="fs-6 fw-semibold">Contact us</div>
                              <div className="phone-text">876-831-1387</div>
                           </div>
                        </a>
                    </div>

                </div>
            </div>
            <div className="col-md-6">
                <div className="box-card contact-us-formbox">
                    <div className="contact-us-form-wrapper">
                        <h2>Login</h2>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <form onSubmit={handleLogin}>
                            <div>
                                <label>Username Or Email:</label><br />
                                <input
                                    type="text"
                                    className="form-input"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div>
                                <label>Password:</label><br />
                                <input
                                    type="password"
                                    className="form-input"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="form-button">Login</button>
                        </form>                           
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section className="testimonial-section bg-gray-light">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="testimonial-heading text-center mb-3 mb-md-5">
                            <h2><span className="text-orange">Testimonials</span></h2>
                            <p className="m-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has <br />
                                been the industry’s standard dummy text ever since the 1500s</p>
                        </div>
                        <div className="testimonial-inner">
                            <div className="testimonial-slider">
                                <div className="testimonial-card bg-white">
                                    <div className="testimonial-header d-flex align-items-center mb-3">
                                        <div className="flex-shrink-0">
                                            <img className="rounded-circle" src="https://extendeddiscsolutions.org/wp-content/uploads/react_images/test-img.jpg" alt="testimonial-img" height="64" width="64" />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="testimonial-author mb-1">Anthony Allison</h6>
                                            <p className="testimonial-position text-gray-dark font-size-14 m-0">Senior Marketing Specialist, Unvab Inc.</p>
                                        </div>
                                    </div>
                                    <div className="stars mb-2">
                                        {/* Star SVG here */}
                                    </div>
                                    <p className="testimonial-text fw-medium text-dark m-0">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </p>
                                </div>
                                {/* Add more testimonial cards here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

<footer className="footer-wrapper">
    <div className="container">
        <div className="row">
            <div className="col-md-6">
                <figure className="footer-logo">
                <Link className="navbar-brand" to="https://www.extendeddiscsolutions.org/">
                    <img src="https://www.extendeddiscsolutions.org/wp-content/uploads/logo/logo-orange.png" alt="footer logo" className="img-fluid" />
                </Link>
                </figure>
                <div className="form-field">
                    <h4>Newsletter Signup</h4>
                    <div className="position-relative">
                        <input id="NewsletterForm" type="email" name="contact[email]" className="form-control form-control-lg" value="" aria-required="true" autocorrect="off" autocapitalize="off" autocomplete="email" placeholder="Email" required="" />
                        <button type="submit" className="newsletter-form__button" name="commit" id="Newsletter-submit" aria-label="Subscribe">
                            <svg className="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 3.74121L13.75 9.99121L7.5 16.2412" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="socials-icons-outer">
                    <h4>Our social links</h4>
                    <ul className="list-unstyled socials-icons d-flex gap-3 m-0">
                        <li><a href="#"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i> Twitter</a></li>
                        <li><a href="#"><i className="fab fa-youtube"></i> Youtube</a></li>
                        <li><a href="#"><i className="fab fa-linkedin"></i> Linkedin</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-12">
                <div className="copyright-content">
                    <small>Copyright © 2024 Extended DISC® Solutions <a href="#">Privacy policy & Terms of use</a></small>
                </div>
            </div>
        </div>
    </div>
</footer>

<div className={`form-container d-flex align-items-center justify-content-center ${isActive ? 'active' : ''}`}>
    <div className="form-inner-box bg-white rounded w-100">
    <div className="contact-us-form-wrapper">
            <span onClick={handleCloseClick} className="close">X</span>
            <h2>Login</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleLogin}>
                <div>
                    <label>Username Or Email:</label><br />
                    <input
                        type="text"
                        className="form-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label><br />
                    <input
                        type="password"
                        className="form-input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="form-button">Login</button>
            </form>                           
        </div>
    </div>
</div>

    </>

    );
}

export default Login;



