import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// Modal Component
const Modal = ({ title, content, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">{content}</div>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
    </div>
);

const CompetencyDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { competency } = location.state || {};
    const [competencyDetails, setCompetencyDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true); // Loading for initial data
    const [loadingMore, setLoadingMore] = useState(false); // Loading for pagination
    const [selectedVideo, setSelectedVideo] = useState(null); // Store selected video for popup

    useEffect(() => {
        if (competency) {
            fetchCompetencyData(1, true); // Initial data load on page 1
        }
    }, [competency]);

    const fetchCompetencyData = async (page, isInitialLoad = false) => {
        isInitialLoad ? setInitialLoading(true) : setLoadingMore(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/extract_report_data', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userId,
                    competency_name: competency.name,
                    page: page,
                }),
            });

            const data = await response.json();
            if (data.status) {
                const newCompetencyDetails = data.data.competences.tips;

                // Remove duplicates by checking if the `id` already exists in the state
                setCompetencyDetails(prevDetails => {
                    const updatedDetails = [...prevDetails, ...newCompetencyDetails];
                    const uniqueDetails = Array.from(new Set(updatedDetails.map(item => item.id)))
                        .map(id => updatedDetails.find(item => item.id === id));
                    return uniqueDetails;
                });
                setHasMore(data.data.has_more);
            } else {
                console.error("No Competency Data Found");
            }
        } catch (error) {
            console.error("Error fetching Competency Data", error);
        } finally {
            isInitialLoad ? setInitialLoading(false) : setLoadingMore(false);
        }
    };

    const loadMore = () => {
        if (hasMore && !loadingMore) {
            setCurrentPage(prevPage => prevPage + 1);
            fetchCompetencyData(currentPage + 1);
        }
    };

    // Function to render content with clickable YouTube URL
    const renderContentWithLinks = (content) => {
        // Regex for matching YouTube URL and converting it to a link
        const youtubeUrlRegex = /(https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)[a-zA-Z0-9_-]+)/g;

        // Replace matched URLs with clickable anchor tags
        return content.replace(youtubeUrlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };

    const openVideoModal = (url) => {
        setSelectedVideo(url); // Set the video URL to display in the modal
      };
    
      const closeModal = () => {
        setSelectedVideo(null); // Clear the selected video
      };
    
      const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split('v=')[1]?.split('&')[0]; // Extract video ID from URL
        return `https://www.youtube.com/embed/${videoId}`;
      };
    
    return (
        <AdminLayout>
            {initialLoading ? (
                <div className='bg-card'>
                    <div class="loader"></div> 
                </div>
            ) : (
                <div className="competency-details">
                    {competency ? (
                        <div>
                           <div className="competency-header">
                                <h4>{competency.name}</h4>
                                {/* Back button aligned to the right */}
                                <button className="back-button" onClick={() => navigate(-1)}>Back</button>
                            </div>

                            <div className='bg-card mt-3'>
                                {/* Display message when no tips are found */}
                                {competencyDetails.length === 0 ? (
                                    <p>Sorry, No tips found.</p>
                                ) : (
                                    <div>
                                        {/* Display tips related to the competency */}
                                        <div>
                                            {competencyDetails.map((tip) => (
                                                <div key={tip.id} className="competency-tip">
                                                    <h5>{tip.category_name}</h5>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                        __html: renderContentWithLinks(tip.content)}}
                                                        onClick={(e) => {
                                                        const href = e.target.getAttribute('href');
                                                        if (href && href.includes('youtube.com')) {
                                                            e.preventDefault();
                                                            openVideoModal(href);
                                                        }
                                                        }}
                                                    ></p>
                                                    
                                                </div>
                                            ))}

                                            {/* Load more button for pagination */}
                                            {hasMore && (
                                                <button className='next-button' onClick={loadMore} disabled={loadingMore}>
                                                    {loadingMore ? <div class="loader"></div> : "Load More"}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    
                                )}

                                {selectedVideo && (
                                <Modal
                                    title="Video"
                                    content={
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={getYouTubeEmbedUrl(selectedVideo)}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="YouTube Video"
                                    ></iframe>
                                    }
                                    onClose={closeModal}
                                />
                                )}
                            </div>
                        </div>
                    ) : (
                        <p>No competency data available.</p>
                    )}
                </div>
            )}
        </AdminLayout>
    );
};

export default CompetencyDetails;
