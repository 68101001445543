import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Modal Component
const Modal = ({ title, content, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content modal-dialog-scrollable" onClick={(e) => e.stopPropagation()}>
            <h2>{title}</h2>
            <div className="modal-body">{content}</div>
            <button className="close-button" onClick={onClose}>X</button>
        </div>
    </div>
);

const Dashboard = () => {
    const navigate = useNavigate();
    const [showWhatInside, setShowWhatInside] = useState(false);
    const [showNewToDisc, setShowNewToDisc] = useState(false);
    const [videoData, setVideoData] = useState([]); // Store all loaded videos
    const [selectedVideo, setSelectedVideo] = useState(null); // Store selected video for popup
    const [page, setPage] = useState(1); // Track current page
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const [hasMore, setHasMore] = useState(true); // If more pages are available

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        loadVideos(); // Initial load
    }, []);

    useEffect(() => {
        if (page > 1) {
            loadVideos(); // Load videos when the page state changes
        }
    }, [page]);

    // Handle scroll to load more videos
    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop ===
                document.documentElement.offsetHeight
            ) {
                // When scrolled to the bottom, load more videos
                if (hasMore && !isLoading) {
                    setPage((prevPage) => prevPage + 1);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasMore, isLoading]);

    const loadVideos = async () => {
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user.user_id;

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'video',
                    category_name: 'how_we_work',
                    user_id: userId,
                    page: page,
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();

            if (data.status && Array.isArray(data.data)) {
                setVideoData((prevData) => [...prevData, ...data.data]);
                setHasMore(data.pagination.has_more); // Update hasMore based on API response
            } else {
                setError("No videos found or status is false.");
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            setError("Failed to load videos.");
        } finally {
            setIsLoading(false);
        }
    };

    const openVideoModal = (video) => {
        setSelectedVideo(video); // Set the video to display in the modal
    };

    // Function to create a YouTube embed link from a YouTube URL
    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split('v=')[1].split('&')[0]; // Extract video ID from URL
        return `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <AdminLayout>
            <section className="assessments_listing">
                <div className="container-fluid">
                    <div className="row video-data">
                    <div class="what-inside">
                        <h2 className="what-inside" onClick={() => setShowWhatInside(!showWhatInside)}>
                                <b>What’s Inside</b>
                                <span className="dropdown-icon">
                                    {showWhatInside ? '▲' : '▼'}
                                </span>
                        </h2>

                {showWhatInside && (
                    <Modal
                        title=""
                        content={
                            <>
                        <h3><b>What's Inside? </b></h3>
                            <p><b>Here is how DISC AI Coach will guide you through your transformation:</b></p>
                            <p>The <b>Instant Coach</b> tab at the bottom of the screen guides you into working harmoniously and productively with any behavioral style from your unique profile. Use the funnel at the top right to search by DISC type.</p>
                            <p>Your <b>Behavioral DNA assessment results</b> are inserted digitally and are accessible from the <b>Report</b> tab at the bottom on the screen. You can get laser coaching, line-by-line, directly from your results. Click on any line to access context-specific training.</p>
                            <p>The <b>More tab</b> at the bottom of the screen provides the opportunity for you to work effectively with <b>Mentors, Coaches or Accountability Partners</b> and select areas of <b>Focus</b> to receive drip content daily in the App. There is also a <b>Resource</b> area stacked with personal development content.</p>
                            <p><b>Deep Dive</b> tab opens up in-depth coaching in <b>Leadership</b> and/or <b>Sales,</b> how to address <b>Pitfalls</b> for your personal behavioral style and <b>Growth</b> guidance for you to thrive.</p>
                            <p>The videos below share the philosophy and concepts that underpin DISC AI Coach and provide guidance as to the mindset and approach that will give you best results.</p>
                            <p>Enjoy the experience even as you move to the <b>Next Level!</b></p>
                            <div className="note"><b>NOTE:</b>Your personal behavioral assessment is not available in Test Drive mode. To get the full benefit of DISC AI Coach activate a subscription which includes your assessment and 12 months of targeted coaching for your unique behavioral profile.</div>
                            <div class="copy-right area">
                                <br></br>
                                DISC AI Coach <br></br>
                            </div> 
                           
                            </>
                        }
                        onClose={() => setShowWhatInside(false)}
                    />
                )}
                        </div>

                        <div class="new-to-disc-ai">
                        <h2 className="new-to-disc-ai" onClick={() => setShowNewToDisc(!showNewToDisc)}>
                                New to DISC?
                                <span className="dropdown-icon">
                                    {showNewToDisc ? '▲' : '▼'}
                                </span>
                        </h2>

                    {showNewToDisc && (
                    <Modal
                        title=""
                        content={
                            <>
                            <h3>New to DISC - Read This</h3>
                            <p>Welcome to the DISC AI Coach App! If you are unfamiliar with DISC, this brief guide will provide you with the foundational knowledge needed to fully benefit from the App’s features.</p>
                            <h3>What is DISC?</h3>
                            <p>DISC is a well-established behavioral model that offers insights into individual personality traits and interpersonal dynamics. In essence, we have four bags of behavioral tools to navigate through life and address life’s unfolding events.</p>
                            <p><b>DISC</b> is the acronym for those four classifications of behaviors:</p>
                            <ul>
                                <li><b>Dominance (D): </b> Focuses on achieving results, is confident, and embraces challenges.</li>
                                <li><b>Influence (I): </b> Outgoing and creative, thrives on interaction and innovation.</li>
                                <li><b>Steadiness (S): </b> Patient, calm, and values stability, teamwork, and collaboration.</li>
                                <li><b>Conscientiousness (C): </b> Detail-oriented, analytical, and appreciates structure, precision, and accuracy</li>
                            </ul>
                            <h3>The Importance of the DISC Classification</h3>
                            <p>Understanding the DISC framework provides significant benefits in both personal and professional contexts by enhancing communication, decision-making, and relationship-building. Knowing your DISC style empowers you to:</p>
                            <ul>
                            <li>Improve interactions with others through a deeper understanding of different communication styles.</li>   
                            <li>Build more cohesive and productive teams by appreciating diverse perspectives.</li>
                            <li>Align your natural strengths with your goals, while identifying areas for personal and professional growth.</li>
                            </ul> 
                            <h3>How the DISC AI Coach App Enhances Your Journey</h3>
                            <p>The DISC AI Coach App provides tailored coaching based on your DISC profile. It offers:</p>
                            <ul>
                            <li>Practical strategies to capitalize on your strengths.</li> 
                            <li>Insightful advice for navigating challenges in alignment with your DISC style.</li> 
                            <li>Continuous support to help refine your communication, leadership or sales capabilities.</li> 
                            </ul>
                            <h3>Your Next Steps</h3>
                            <ul>
                                <li>1. Discover Your DISC Style: Begin by completing your DISC behavioral assessment (or access it from the Report tab) to unlock detailed, personalized insights.</li>
                                <li>2. Explore Tailored Coaching: The app will guide you through customized coaching strategies designed for leadership, sales, and personal development.</li>
                                <li>3. Implement and Thrive: Apply the guidance from your coaching sessions to real- world scenarios and observe how your interactions and performance improve.</li>
                            </ul>
                            <div class="copy-right area">
                                Thanks <br></br>
                                DISC AI Coach <br></br>
                                Email: info@successwithpeople.org

                            </div>
                            </>
                        }
                        onClose={() => setShowNewToDisc(false)}
                    />
                )}
                    </div>
                        <div className="video-gallery">
                            {isLoading && page === 1 ? (
                                <div className="loader"></div>
                            ) : error ? (
                                <p>{error}</p>
                            ) : videoData.length > 0 ? (
                                videoData.map((video) => (
                                    <div className="video-card" key={video.id}>
                                        <a href="#" onClick={() => openVideoModal(video)}>
                                            <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                                        </a>
                                        <h3 className="video-title">{video.title}</h3>
                                    </div>
                                ))
                            ) : (
                                <p>No videos available.</p>
                            )}
                        </div>

                        {selectedVideo && (
                            <Modal
                                title={selectedVideo.title}
                                content={
                                    <div>
                                        {/* Debugging logs */}
                                        <p>Video URL: {selectedVideo.name}</p>

                                        {/* If the video is from YouTube, embed it using iframe */}
                                        {selectedVideo.name.includes('youtube.com') ? (
                                            <iframe
                                                width="100%"
                                                height="315"
                                                src={getYouTubeEmbedUrl(selectedVideo.name)}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                                title={selectedVideo.title}
                                            ></iframe>
                                        ) : (
                                            // Handle regular video file format (MP4, WebM, OGG)
                                            <video controls width="100%">
                                                <source src={selectedVideo.name} type="video/mp4" />
                                                <source src={selectedVideo.name.replace(".mp4", ".webm")} type="video/webm" />
                                                <source src={selectedVideo.name.replace(".mp4", ".ogv")} type="video/ogg" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </div>
                                }
                                onClose={() => setSelectedVideo(null)}
                            />
                        )}
                    </div>
                </div>
                {isLoading && page > 1 && <div className='bg-card'><div className="loader"></div></div>}
                {hasMore && !isLoading && (
                    <div className="scroll-message">
                        <p>Scroll down to see more videos</p>
                    </div>
                )}
                <div className="home-button">
                    <Link to="/user-profile" className="home-next next-button">NEXT</Link>
                </div>
            </section>
        </AdminLayout>
    );
};

export default Dashboard;
