import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';


function LogoutButton() {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        navigate('/login'); // Redirect to login page after logout
    };

    return (
        <a onClick={handleLogout} href="#" className="logout_btn_head">
            Log out <img src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/logouticonn.svg" alt="logout_icon" />
        </a>
    );
}

const AdminLayout = ({ children }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [activeMenu, setActiveMenu] = useState('/dashboard');
    const [showPopup, setShowPopup] = useState(false); // Manage popup visibility
    const user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);

    // Toggle the active state when the button is clicked
    const handleSidebarToggle = () => {
        setIsActive(!isActive);
    };
    
    const handleCloseSidebar = () => {
        setIsActive(false); // This will remove the 'active' class
    };

    const fetchNotificationCount = async () => {
        if (!user || !user.user_id) return; // Ensure user is logged in
    
        try {
            const response = await axios.post(
                'https://www.extendeddiscsolutions.org/wp-json/custom/v1/notifications',
                { user_id: user.user_id },
                { headers: { 'Content-Type': 'application/json' } }
            );
    
            if (response.status === 200) {
                const notifications = response.data.data || [];
                
                // Get today's date in YYYY-MM-DD format
                const today = new Date().toISOString().split('T')[0]; // Get only the date part (YYYY-MM-DD)
    
                // Filter notifications to show only those from today's date
                const newNotifications = notifications.filter(notification => {
                    return notification.date === today; // Compare only the date part
                });
    
                const newNotificationCount = newNotifications.length;
                setNotificationCount(newNotificationCount); // Update the count
            }
        } catch (error) {
            console.error('Error fetching notification count:', error);
        }
    };

    // Reset notification count when navigating to the notifications page
    const handleNotificationClick = async () => {
        if (!user || !user.user_id) return; // Ensure user is logged in

        // Reset notification count on client side immediately
        setNotificationCount(0);

        // Optional: Call API to reset notifications on server if needed
        try {
            const response = await axios.post(
                'https://www.extendeddiscsolutions.org/wp-json/custom/v1/notifications',
                { user_id: user.user_id },
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (response.status === 200) {
                console.log('Notification count reset on the server.');
            }
        } catch (error) {
            console.error('Error resetting notification count:', error);
        }
    };

    // Fetch notification count when the component mounts
    useEffect(() => {
        fetchNotificationCount(); // Fetch count on mount

        // Optional: Set up an interval to refresh the count periodically
        const interval = setInterval(() => {
            fetchNotificationCount();
        }, 60000); // Refresh every minute

        return () => clearInterval(interval); // Cleanup on unmount
    }, [user]);

    // Update the active menu when the route changes
    useEffect(() => {
        setActiveMenu(location.pathname);
    }, [location]);

    // Toggle sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };


    const deleteUserAccount = async () => {
        if (!user || !user.user_id) {
            alert('User not logged in.');
            return;
        }

        try {
            const response = await axios.post(
                'https://www.extendeddiscsolutions.org/wp-json/custom/v1/delete_user',
                { user_id: user.user_id }, // Dynamic user ID
                { headers: { 'Content-Type': 'application/json' } }
            );

            if (response.status === 200 && response.data.status) {
                alert(response.data.data.message || 'Account deleted successfully.');
                localStorage.removeItem('isLoggedIn'); // Clear local storage
                navigate('/login'); // Redirect to login page
            } else {
                alert(response.data.message || 'Failed to delete account.');
            }
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('An error occurred. Please try again.');
        }

        setShowPopup(false); // Close popup
    };


    return (
        <div className="dashboard">
            <div className="wrapper d-flex align-items-stretch">
            <nav id="sidebar" className={`sidebar-mobile ${isActive ? 'active' : ''}`}>
                    <span onClick={handleCloseSidebar} className='close'>X</span>
                    <div class="header-new-logo mobile">
                        <img
                            src="https://extendeddiscsolutions.org/wp-content/uploads/logo/new-app-logo.png"
                            alt="logo"
                            className="logo_header_top_new"
                        />

                    </div>
                    <div className="p-2 pt-5">
                        <ul className="list-unstyled components mb-5 menu_left_main">
                        <li className={activeMenu === '/dashboard' ? 'active' : ''}>
                                <Link to="/dashboard"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9.5L12 3l9 6.5V20a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9.5z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>Home</Link>
                            </li>
                            <li className={activeMenu === '/instant-coach' ? 'active' : ''}>
                                <Link to="/instant-coach"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-headphones"><circle cx="12" cy="12" r="10"></circle><path d="M16 12h2a4 4 0 0 1 0 8h-2"></path><path d="M8 12H6a4 4 0 0 0 0 8h2"></path></svg>
                                Instant Coach</Link>
                            </li>
                            <li className={activeMenu === '/all-report/' ? 'active' : ''}>
                                <Link to="/all-report/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bar-chart"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                                Report</Link>
                            </li>
                            <li className={activeMenu === '/user-profile' ? 'active' : ''}>
                                <Link to="/user-profile"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-3-3.87"></path><path d="M4 21v-2a4 4 0 0 1 3-3.87"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                Mentor</Link>
                            </li>
                            <li className={activeMenu === '/focus-areas' ? 'active' : ''}>
                                <Link to="/focus-areas"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                                Focus Areas</Link>
                            </li>
                            <li className={activeMenu === '/sales-resources' ? 'active' : ''}>
                                <Link to="/sales-resources"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 3H8a2 2 0 0 0-2 2v2h12V5a2 2 0 0 0-2-2z"></path></svg>
                                Sales Resources</Link>
                            </li>
                            <li className={activeMenu === '/leadership-resources' ? 'active' : ''}>
                                <Link to="/leadership-resources"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-3-3.87"></path><path d="M7 21v-2a4 4 0 0 1 3-3.87"></path><circle cx="12" cy="7" r="4"></circle><path d="M22 11.07A4 4 0 0 0 16.87 7"></path><path d="M7.13 7A4 4 0 0 0 2 11.07"></path></svg>
                                Leadership Resources</Link>
                            </li>
                            <li className={activeMenu === '/deepdive' ? 'active' : ''}>
                                <Link to="/deepdive"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                <path d="M12 4C7.03 4 3 8 3 12s4.03 8 9 8 9-4 9-8-4.03-8-9-8z"></path>
                                <path d="M12 14c-1.49 0-3-1.34-3-3s1.51-3 3-3 3 1.34 3 3-1.51 3-3 3z"></path>
                                </svg>

                                Deep Dive</Link>
                            </li>
                            <li className={activeMenu === '/delete' ? 'active' : ''}>
                                <Link to="#" onClick={() => setShowPopup(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6l-1.5 14a2 2 0 0 1-2 2H8.5a2 2 0 0 1-2-2L5 6"></path>
                                <path d="M10 11v6"></path>
                                <path d="M14 11v6"></path>
                                <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2"></path>
                                </svg>
                                Delete Account</Link>
                            </li>
                            <li className="logout_left">
                                <LogoutButton />
                            </li>
                            
                        </ul>
                    </div>
                </nav>
                <nav id="sidebar" className={isSidebarCollapsed ? 'collapsed' : ''}>
                    <div className="p-2 pt-5">
                        <div className="sidebar-logo-wrapper">
                           <Link to="/dashboard/">
                            <img
                                src="https://www.extendeddiscsolutions.org/wp-content/uploads/logo/logo-orange.png"
                                alt="logo"
                                className="logo_header_top"
                            />
                            
                            </Link>
                            <div class="header-new-logo">
                            <img
                                src="https://extendeddiscsolutions.org/wp-content/uploads/logo/new-app-logo.png"
                                alt="logo"
                                className="logo_header_top_new"
                            />

                            </div>
                            <span className='logo_info'>DISC AI Coach leverages Extended DISC Intellectual property.</span>
                        </div>
                        
                        <ul className="list-unstyled components mb-5 menu_left_main">
                            <li className={activeMenu === '/dashboard' ? 'active' : ''}>
                                <Link to="/dashboard"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9.5L12 3l9 6.5V20a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9.5z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>Home</Link>
                            </li>
                            <li className={activeMenu === '/instant-coach' ? 'active' : ''}>
                                <Link to="/instant-coach"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-headphones"><circle cx="12" cy="12" r="10"></circle><path d="M16 12h2a4 4 0 0 1 0 8h-2"></path><path d="M8 12H6a4 4 0 0 0 0 8h2"></path></svg>
                                Instant Coach</Link>
                            </li>
                            <li className={activeMenu === '/all-report/' ? 'active' : ''}>
                                <Link to="/all-report/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bar-chart"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                                Report</Link>
                            </li>
                            <li className={activeMenu === '/user-profile' ? 'active' : ''}>
                                <Link to="/user-profile"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-3-3.87"></path><path d="M4 21v-2a4 4 0 0 1 3-3.87"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                Mentor</Link>
                            </li>
                            <li className={activeMenu === '/focus-areas' ? 'active' : ''}>
                                <Link to="/focus-areas"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                                Focus Areas</Link>
                            </li>
                            <li className={activeMenu === '/sales-resources' ? 'active' : ''}>
                                <Link to="/sales-resources"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase"><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 3H8a2 2 0 0 0-2 2v2h12V5a2 2 0 0 0-2-2z"></path></svg>
                                Sales Resources</Link>
                            </li>
                            <li className={activeMenu === '/leadership-resources' ? 'active' : ''}>
                                <Link to="/leadership-resources"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-3-3.87"></path><path d="M7 21v-2a4 4 0 0 1 3-3.87"></path><circle cx="12" cy="7" r="4"></circle><path d="M22 11.07A4 4 0 0 0 16.87 7"></path><path d="M7.13 7A4 4 0 0 0 2 11.07"></path></svg>
                                Leadership Resources</Link>
                            </li>
                            <li className={activeMenu === '/deepdive' ? 'active' : ''}>
                                <Link to="/deepdive"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                <path d="M12 4C7.03 4 3 8 3 12s4.03 8 9 8 9-4 9-8-4.03-8-9-8z"></path>
                                <path d="M12 14c-1.49 0-3-1.34-3-3s1.51-3 3-3 3 1.34 3 3-1.51 3-3 3z"></path>
                                </svg>

                                Deep Dive</Link>
                            </li>
                            <li className={activeMenu === '/delete' ? 'active' : ''}>
                                <Link to="#" onClick={() => setShowPopup(true)} ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6l-1.5 14a2 2 0 0 1-2 2H8.5a2 2 0 0 1-2-2L5 6"></path>
                                <path d="M10 11v6"></path>
                                <path d="M14 11v6"></path>
                                <path d="M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2"></path>
                                </svg>
                                Delete Account</Link>
                            </li>
                            <li className="logout_left">
                                <LogoutButton />
                            </li>
                           
                        </ul>
                    </div>
                </nav>
                
                {/* Main Content */}
                <div id="content">
                    {/* Main content area */}
                    <div class="mobile-header">
                    <div className="sidebar-logo-wrapper">
                                <Link to="/dashboard/">
                                <img
                                    src="https://www.extendeddiscsolutions.org/wp-content/uploads/logo/logo-orange.png"
                                    alt="logo"
                                    className="logo_header_top"
                                />
                                
                                </Link>
                                
                                <ul className="nav navbar-nav">
                                   
                                    <span className='logo_info'>DISC AI Coach leverages Extended DISC Intellectual property.</span>

                                    {/* Notification Bell */}
                                    <li className="nav-item btn_notification_right">
                                        <Link
                                            className="nav-link"
                                            to="/notifications"
                                            id="notification-bell"
                                            onClick={handleNotificationClick} // Reset count when clicked
                                        >
                                            <img
                                                src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/bel_notification.png"
                                                alt="Notification_icon"
                                            />
                                            <span className="notification-count">
                                                {notificationCount}
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                                <button
                                    type="button"
                                    id="sidebarCollapse"
                                    className="btn btn-primary mr-3"
                                    onClick={handleSidebarToggle}
                                >
                                    <i className="fa fa-bars"></i>
                                    <span className="sr-only">Toggle Menu</span>
                                </button>
                            </div>
                            
                            </div>
                            <div className="container-fluid">
                                <nav id="sidebar" class="">
                                <ul className="nav navbar-nav">
                                        {/* Notification Bell */}
                                        <span className="user-info">
                                            Welcome Back, {user && <span>{user.username}</span>}
                                        </span>
                                        <li className="nav-item btn_notification_right">
                                            <Link
                                                className="nav-link"
                                                to="/notifications"
                                                id="notification-bell"
                                                onClick={handleNotificationClick} // Reset count when clicked
                                            >
                                                <img
                                                    src="https://www.extendeddiscsolutions.org/wp-content/themes/astra-child/assets/images/bel_notification.png"
                                                    alt="Notification_icon"
                                                />
                                                <span className="notification-count">
                                                    {notificationCount}
                                                </span>
                                            </Link>
                                        </li>
                                </ul>
                                </nav>
                                    {/* Popup Modal */}
                                    {showPopup && (
                                        <div className="popup-modal delete-function">
                                            <div className="popup-content">
                                                <h3>Confirm Deletion</h3>
                                                <p>Are you sure you want to delete your account? This action cannot be undone.</p>
                                                <div className="popup-actions">
                                                    <button onClick={deleteUserAccount} className="btn btn-danger delete">Yes, Delete</button>
                                                    <button onClick={() => setShowPopup(false)} className="btn btn-secondary cancel">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {children}
                                </div>
                            </div>
                </div>
            </div>
    );
};

export default AdminLayout;
