import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../components/common/AdminLayout";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

// Modal Component
const Modal = ({ title, content, onClose }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <div className="modal-body">{content}</div>
      <button className="close-button" onClick={onClose}>X</button>
    </div>
  </div>
);

const SalesReport = () => {
  const navigate = useNavigate();
  const [leadershipReport, setLeadershipReport] = useState(null);
  const [leadershipReportPagination, setLeadershipReportPaginations] = useState({});
  const [loading, setLoading] = useState(true);
  const [openSections, setOpenSections] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [mainCategoriesPagination, setMainCategoriesPagination] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null); // Store selected video for popup

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (!loggedIn) {
      navigate("/login"); // Redirect to login if not logged in
    }
  }, [navigate]);

  const fetchLeadershipReport = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.user_id;
      

      const response = await fetch("https://www.extendeddiscsolutions.org/wp-json/custom/v1/sales_report", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ user_id: userId }),
      });

      const data = await response.json();
      if (data.status) {
        setLeadershipReport(data);
      } else {
        setLeadershipReport(null);
      }
    } catch (error) {
      console.error("Error fetching Report", error);
      setLeadershipReport(null);
    } finally {
      setLoading(false);
    }
  };

  const toggleSection = (id, competencyName) => {
    if (!mainCategoriesPagination[competencyName]) {
      setMainCategoriesPagination((prev) => ({
        ...prev,
        [competencyName]: {
          page: 1, // Initialize with page 1
          items: [],
          hasMore: true,
          loading: false, // Add loading flag
        },
      }));
    }

    setOpenSections((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleCompetencyClick = (competency) => {
    if (!leadershipReportPagination[competency.name]) {
      setLeadershipReportPaginations((prev) => ({
        ...prev,
        [competency.name]: {
          page: 1, // Initialize with page 1
          items: [],
          hasMore: true,
          loading: false, // Add loading flag
        },
      }));
    }

    setOpenSections((prevState) => ({
      ...prevState,
      [competency.name]: !prevState[competency.name],
    }));
  };

  const fetchLeadershipReportPaginations = async (competencyName, page) => {
    try {
      // Show loader for pagination
      setIsLoading(true); // Display loader for paginated data fetch

      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.user_id;

      const response = await fetch("https://www.extendeddiscsolutions.org/wp-json/custom/v1/report_pagination", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          name: competencyName,
          page: page,
        }),
      });

      const data = await response.json();

      if (data.status) {
        setLeadershipReportPaginations((prevData) => ({
          ...prevData,
          [competencyName]: {
            items: [...(prevData[competencyName]?.items || []), ...data.data.competences],
            hasMore: data.data.has_more_competences,
            page: page, // Update page number
          },
        }));
      } else {
        setLeadershipReportPaginations((prevData) => ({
          ...prevData,
          [competencyName]: {
            items: [...(prevData[competencyName]?.items || [])],
            hasMore: false,
            page: page, // Update page number
          },
        }));
        setIsLoading(false);
        console.error(`Failed to fetch data for Page ${page}`);
      }
    } catch (error) {
      console.error("Error fetching Report Pagination", error);
    } finally {
      // Hide loader after the pagination data is fetched
      setIsLoading(false);
    }
  };

  const scrollRefs = useRef({});

  const handleScroll = (competencyName, scrollDiv) => {
    const paginationData = leadershipReportPagination[competencyName] || {};
    if (!paginationData || paginationData.loading || !paginationData.hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollDiv;
    const bottomReached = scrollTop + clientHeight >= scrollHeight - 10;

    if (bottomReached) {
      const currentPage = paginationData.page || 1;
      const nextPage = currentPage + 1;

      setLeadershipReportPaginations((prev) => ({
        ...prev,
        [competencyName]: {
          ...prev[competencyName],
          loading: true,
        },
      }));

      fetchLeadershipReportPaginations(competencyName, nextPage).then(() => {
        setLeadershipReportPaginations((prev) => ({
          ...prev,
          [competencyName]: {
            ...prev[competencyName],
            page: nextPage,
            loading: false,
          },
        }));
      });
    }
  };

  const handleCompetencyClickNavigate = (competency) => {
    navigate("/competency-details", { state: { competency } });
  };

  useEffect(() => {
    fetchLeadershipReport();
  }, []);

  const user = JSON.parse(localStorage.getItem("user"));
  const userName = user.username;

  const fetchMainCategoryPagination = async (categoryName, page) => {
    try {
      setIsLoading(true); // Show loader for main_categories
      const user = JSON.parse(localStorage.getItem("user"));
      const userId = user.user_id;

      const response = await fetch("https://www.extendeddiscsolutions.org/wp-json/custom/v1/report_pagination", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userId,
          name: categoryName,
          page: page,
        }),
      });

      const data = await response.json();

      if (data.status) {
        if (data.data?.main_categories) {
          setMainCategoriesPagination((prevData) => ({
            ...prevData,
            [categoryName]: {
              items: [...(prevData[categoryName]?.items || []), ...data.data.main_categories[0].subcategories],
              hasMore: data.data.has_more_main_categories,
              page: page,
            },
          }));
        } else {
          setMainCategoriesPagination((prevData) => ({
            ...prevData,
            [categoryName]: {
              ...prevData[categoryName],
              hasMore: false,
            },
          }));
        }
      } else {
        setMainCategoriesPagination((prevData) => ({
          ...prevData,
          [categoryName]: {
            ...prevData[categoryName],
            hasMore: false,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching main categories pagination", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleMainCategoryScroll = (categoryName, scrollDiv) => {
    const paginationData = mainCategoriesPagination[categoryName] || {};
    if (!paginationData || paginationData.loading || !paginationData.hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollDiv;
    const bottomReached = scrollTop + clientHeight >= scrollHeight - 10;

    if (bottomReached) {
      const currentPage = paginationData.page || 1;
      const nextPage = currentPage + 1;

      setMainCategoriesPagination((prev) => ({
        ...prev,
        [categoryName]: {
          ...prev[categoryName],
          loading: true,
        },
      }));

      fetchMainCategoryPagination(categoryName, nextPage).then(() => {
        setMainCategoriesPagination((prev) => ({
          ...prev,
          [categoryName]: {
            ...prev[categoryName],
            page: nextPage,
            loading: false,
          },
        }));
      });
    }
  };

  const makeLinksClickable = (content) => {
    // Regular expression to match YouTube URLs
    const youtubeRegex = /(https?:\/\/(?:www\.)?youtube\.com\/(?:[^ ]+))/g;
  
    // Replace the YouTube URLs with anchor tags
    return content.replace(youtubeRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const makeNewLines = (content) => {
    // Replace <br> tags with newline characters
    const formattedContent = content.replace(/<br\s*\/?>/g, '\n');
  
    // Split the content into an array of strings, each representing a line
    return formattedContent.split('\n').map((line, index) => (
      <p key={index}>{line}</p>  // Wrap each line in a <p> element
    ));
  };

  const openVideoModal = (url) => {
    setSelectedVideo(url); // Set the video URL to display in the modal
  };

  const closeModal = () => {
    setSelectedVideo(null); // Clear the selected video
  };

  const getYouTubeEmbedUrl = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0]; // Extract video ID from URL
    return `https://www.youtube.com/embed/${videoId}`;
  };

  
  return (
    <AdminLayout>
      {loading ? (
        <div className="loader"></div>
      ) : leadershipReport === null ? (
        <div className="no-report-message">
          <p>The assessment report is not ready yet.</p>
        </div>
      ) : (
        <section className="assessments_listing">
          <div id="content">
            <div className="report-content">
              <div className="bg-card">
                <div className="container-fluid first_report_dowenloadvs">
                  <div className="report-head">
                    <h3>{leadershipReport.data.report_title}</h3>
                    <Link to={leadershipReport.data.report_pdf_link} className="download-report" target="_blank" rel="noopener noreferrer">
                      Download Report
                    </Link>
                  </div>

                  <p>
                    <span>D</span>
                    <span>I</span>
                    <span>S</span>
                    <span>C</span>
                  </p>

                  <div className="competency-data">
                    {leadershipReport.data.main_categories.map((category) => {
                      const paginationData = mainCategoriesPagination[category.name] || {};
                      return (
                        <div key={category.id}>
                          <h5 onClick={() => toggleSection(category.id, category.name)}>{category.name}</h5>

                          {openSections[category.id] && (
                            <div
                              onScroll={(e) => handleMainCategoryScroll(category.name, e.target)}
                              ref={(el) => (scrollRefs.current[category.name] = el)}
                              className="category-data"
                              style={{
                                height: "250px",
                                overflow: "auto",
                              }}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: makeLinksClickable(category.content.replace(/\$u_name/g, userName)),
                                }}
                                onClick={(e) => {
                                  const href = e.target.getAttribute('href');
                                  if (href && href.includes('youtube.com')) {
                                    e.preventDefault();
                                    openVideoModal(href);
                                  }
                                }}
                              ></p>
                              {category.subcategories.length > 0 && (
                                <div className="subcategories">
                                  {category.subcategories.map((subcategory) => (
                                    <div key={subcategory.id}>
                                      <h6>{subcategory.name}</h6>
                                      <p>
                                        {makeNewLines(subcategory.content.replace(/\$u_name/g, userName))}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )}
                              {paginationData.items.map((item, index) => (
                                  <div key={index}>
                                    <div className="subcategories">
                                      <div>
                                        <h6>{item.name}</h6>
                                        <p> {makeNewLines(item.content.replace(/\$u_name/g, userName))}</p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {isLoading && <div className="loader"></div>}
                            </div>
                          )}
                        </div>
                      );
                    })}

                    {selectedVideo && (
                      <Modal
                        title="Video"
                        content={
                          <iframe
                            width="100%"
                            height="315"
                            src={getYouTubeEmbedUrl(selectedVideo)}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="YouTube Video"
                          ></iframe>
                        }
                        onClose={closeModal}
                      />
                    )}
                  </div>

                  {leadershipReport.data.competences.map((competence) => {
                    const paginationData = leadershipReportPagination[competence.name] || {}; // Get paginated data for the competency
                    return (
                      <div key={competence.name}>
                        <h5 onClick={() => handleCompetencyClick(competence)}>{competence.name}</h5>

                        {openSections[competence.name] && (
                          <div
                            onScroll={() => handleScroll(competence.name, scrollRefs.current[competence.name])}
                            ref={(el) => (scrollRefs.current[competence.name] = el)}
                            className="category-data"
                            style={{ height: "250px", overflow: "auto" }}
                          >
                            {/* Render competence items */}
                            {competence.my_competences.map((comp, index) => (
                              <div key={index}>
                                <h4>{comp.name}</h4>
                                <ul className="charts">
                                  {Array.from({ length: 11 }, (_, i) => i - 5).map((score) => (
                                    <li key={score} className={score === comp.person_score ? "slected_li" : ""}>
                                      <button onClick={() => handleCompetencyClickNavigate(comp)}>{score}</button>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ))}

                            {paginationData.items.length > 0 && (
                              <div className="pagination-items">
                                {paginationData.items.map((item, index) => (
                                  <div key={index}>
                                    {Array.isArray(item.my_competences) && item.my_competences.length > 0 ? (
                                      item.my_competences.map((competence, idx) => (
                                        <div key={idx}>
                                          <h4>{competence.name}</h4>
                                          <ul className="charts">
                                            {Array.from({ length: 11 }, (_, i) => i - 5).map((score) => (
                                              <li key={score} className={score === competence.person_score ? "slected_li" : ""}>
                                                <button onClick={() => handleCompetencyClickNavigate(competence)}>{score}</button>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      ))
                                    ) : (
                                      <p>No competences available</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                            {/* Show loader for each item if pagination data is loading */}
                            {isLoading && <div className="loader"></div>}
                            {/* Ensure items are displayed under the correct competency */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </AdminLayout>
  );
};

export default SalesReport;
