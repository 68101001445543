import { useState, useEffect } from 'react';
import AdminLayout from '../components/common/AdminLayout';
import { useNavigate } from 'react-router-dom';

// Modal Component
const Modal = ({ title, content, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{title}</h2>
            <div className="modal-body">{content}</div>
            <button className="close-button" onClick={onClose}>X</button>
        </div>
    </div>
);

const InstantCoach = () => {
    const navigate = useNavigate();
    const [videoData, setVideoData] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [toStyle, setToStyle] = useState('');
    const [fromStyle, setFromStyle] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null); // Store selected video for popup

    useEffect(() => {
        const loggedIn = localStorage.getItem('isLoggedIn');
        if (!loggedIn) {
            navigate('/login'); // Redirect to login if not logged in
        }
    }, [navigate]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const userDiscType = user ? user.disc_type : '';
        setToStyle(userDiscType);
    }, []);

    useEffect(() => {
        loadVideos();
    }, [page, toStyle, fromStyle]);

    useEffect(() => {
        const handleScroll = () => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 200 &&
                !isLoading &&
                hasMore
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, hasMore]);

    const loadVideos = async () => {
        setIsLoading(true);
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user ? user.user_id : '';

            const response = await fetch('https://www.extendeddiscsolutions.org/wp-json/custom/v1/resources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    type: 'video',
                    category_name: 'quick_connect',
                    user_id: userId,
                    search: toStyle,
                    content_type: fromStyle,
                    page: page,
                }),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();
            if (data.status && Array.isArray(data.data)) {
                setVideoData((prevData) => (page === 1 ? data.data : [...prevData, ...data.data]));
                setHasMore(data.pagination.has_more);
            } else {
                setError("No videos found or status is false.");
                setHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching videos:", error);
            setError("Failed to load videos.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleToStyleChange = (e) => {
        setToStyle(e.target.value);
        setPage(1);
        setVideoData([]);
    };

    const handleFromStyleChange = (e) => {
        setFromStyle(e.target.value);
        setPage(1);
        setVideoData([]);
    };

    const getShortDescription = (description) => {
        const words = description.split(' ');
        return words.slice(0, 10).join(' ') + (words.length > 10 ? '...' : '');
    };

    const openVideoModal = (video) => {
        setSelectedVideo(video); // Set the video to display in the modal
    };

    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split('v=')[1].split('&')[0]; // Extract video ID from URL
        return `https://www.youtube.com/embed/${videoId}`;
    };

    return (
        <AdminLayout>
            <h3>Quick connect</h3>
            <p>Increase your impact and influence from the outset by following expert coaching as to how to work effectively and harmoniously with any behavioral style from your profile. Use the search and funnel filters to fine-tune your searches.</p>
            <div className='filter-block'>
                <div className='filter-left-block'>
                    <label>To Style</label>
                    <select value={toStyle} onChange={handleToStyleChange}>
                        <option value="">Select To Style</option>
                        <option value="DI">DI</option>
                        <option value="DS">DS</option>
                        <option value="DC">DC</option>
                        <option value="ID">ID</option>
                        <option value="IS">IS</option>
                        <option value="IC">IC</option>
                        <option value="SD">SD</option>
                        <option value="SI">SI</option>
                        <option value="SC">SC</option>
                        <option value="CI">CI</option>
                        <option value="CS">CS</option>
                    </select>
                </div>
                <div className='filter-right-block'>
                    <label>From Style</label>
                    <select value={fromStyle} onChange={handleFromStyleChange}>
                        <option value="">Select From Style</option>
                        <option value="D">Dominance (D)</option>
                        <option value="I">Influence (I)</option>
                        <option value="S">Steadiness (S)</option>
                        <option value="C">Conscientiousness (C)</option>
                        <option value="All">All</option>
                    </select>
                </div>
            </div>

            <div className="video-gallery">
                {isLoading && page === 1 ? (
                    <div className="loader"></div>
                ) : error ? (
                    <p>{error}</p>
                ) : videoData.length > 0 ? (
                    videoData.map((video) => (
                        <div className="video-card" key={video.id}>
                            <a href="#" onClick={() => openVideoModal(video)}>
                                <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                            </a>
                            <h3 className="video-title">{video.title}</h3>
                            <p className="description">{getShortDescription(video.description)}</p>
                        </div>
                    ))
                ) : (
                    <p>No videos available.</p>
                )}
            </div>

            {isLoading && page > 1 && <div className='bg-card'><div className="loader"></div></div>}
                {hasMore && !isLoading && (
                    <div className="scroll-message">
                        <p>Scroll down to see more videos</p>
                    </div>
                )}

            {selectedVideo && (
                <Modal
                    title={selectedVideo.title}
                    content={
                        <div>
                            {/* If the video is from YouTube, embed it using iframe */}
                            {selectedVideo.name.includes('youtube.com') ? (
                                <iframe
                                    width="100%"
                                    height="315"
                                    src={getYouTubeEmbedUrl(selectedVideo.name)}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title={selectedVideo.title}
                                ></iframe>
                            ) : (
                                // Handle regular video file format (MP4, WebM, OGG)
                                <video controls width="100%">
                                    <source src={selectedVideo.name} type="video/mp4" />
                                    <source src={selectedVideo.name.replace(".mp4", ".webm")} type="video/webm" />
                                    <source src={selectedVideo.name.replace(".mp4", ".ogv")} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    }
                    onClose={() => setSelectedVideo(null)}
                />
            )}
        </AdminLayout>
    );
};

export default InstantCoach;
