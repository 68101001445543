import { useState, useEffect, useCallback } from "react";
import AdminLayout from "../components/common/AdminLayout";
import { useNavigate } from "react-router-dom";

// Modal Component
const Modal = ({ title, content, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-body">{content}</div>
        <button className="close-button" onClick={onClose}>X</button>
      </div>
    </div>
);

const LeadershipProgram = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingTips, setIsLoadingTips] = useState(false);
    const [error, setError] = useState(null);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [tips, setTips] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreTips, setHasMoreTips] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null); // Store selected video for popup
    

    useEffect(() => {
        const loggedIn = localStorage.getItem("isLoggedIn");
        if (!loggedIn) {
            navigate("/login");
        }
    }, [navigate]);

    useEffect(() => {
        const fetchCategories = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_categories_by_program",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ program_id: "leadership_program" }),
                    }
                );

                if (!response.ok) {
                    throw new Error(`Failed to fetch categories: ${response.status}`);
                }

                const data = await response.json();
                if (data.status && data.data && data.data.competences && data.data.competences.length > 0) {
                    setCategories(data.data.competences);
                    setSelectedCategory(data.data.competences[0].category_name);
                } else {
                    setError("No categories found.");
                }
            } catch (err) {
                setError("Failed to load categories.");
            } finally {
                setIsLoading(false);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!selectedCategory) return;

        const fetchTips = async () => {
            setIsLoadingTips(true);
            try {
                const response = await fetch(
                    "https://www.extendeddiscsolutions.org/wp-json/custom/v1/get_tips",
                    {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({
                            competency_name: selectedCategory,
                            program_id: "leadership_program",
                            page: currentPage,
                        }),
                    }
                );

                const data = await response.json();
                if (data.status && data.data && Array.isArray(data.data.tips)) {
                    setTips((prevTips) =>
                        currentPage === 1 ? data.data.tips : [...prevTips, ...data.data.tips]
                    );
                    setHasMoreTips(data.data.has_more);
                } else {
                    setTips([]);
                }
            } catch (err) {
                setError("Failed to load tips.");
            } finally {
                setIsLoadingTips(false);
            }
        };

        fetchTips();
    }, [selectedCategory, currentPage]);

    const handleCategoryChange = (event) => {
        const selectedCategoryObj = categories.find(
            (category) => category.id === event.target.value
        );
        setSelectedCategory(selectedCategoryObj.category_name);
        setCurrentPage(1);
        setTips([]);
    };

    const handleScroll = useCallback(() => {
        const bottom =
            window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
        if (bottom && !isLoadingTips && hasMoreTips) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    }, [isLoadingTips, hasMoreTips]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("touchmove", handleScroll, { passive: true });
        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("touchmove", handleScroll);
        };
    }, [handleScroll]);

    // Function to render content with clickable YouTube URL
    const renderContentWithLinks = (content) => {
        // Regex for matching YouTube URL and converting it to a link
        const youtubeUrlRegex = /(https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/)[a-zA-Z0-9_-]+)/g;

        // Replace matched URLs with clickable anchor tags
        return content.replace(youtubeUrlRegex, (url) => {
            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
    };

    const openVideoModal = (url) => {
        setSelectedVideo(url); // Set the video URL to display in the modal
    };
    
    const closeModal = () => {
        setSelectedVideo(null); // Clear the selected video
    };
    
    const getYouTubeEmbedUrl = (url) => {
        const videoId = url.split('v=')[1]?.split('&')[0]; // Extract video ID from URL
        return `https://www.youtube.com/embed/${videoId}`;
    };
    

    return (
        <>
            <h3>Leadership Program</h3>
            <p>
                Use the drop-down menu to access coaching on any of the range of
                competencies from your behavioral assessment. Inserted videos add
                insights, press on the links to open them.
            </p>
            <div className="bg-card">
                {error && <p style={{ color: "red" }}>{error}</p>}
                {isLoading && <div className="loader"></div>}
                {!isLoading && categories && categories.length > 0 && (
                    <div>
                        {/* Dropdown for categories */}
                        <select
                            id="category-select"
                            value={categories.find(category => category.category_name === selectedCategory)?.id || ""}
                            onChange={handleCategoryChange}
                            style={{ marginBottom: "1em" }}
                        >
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.category_name}
                                </option>
                            ))}
                        </select>

                        {/* Display tips */}
                        <div>
                            {tips.map((tip) => (
                                <div key={tip.id} style={{ marginBottom: "1em" }}>
                                    <h5>{tip.category_name}</h5>
                                    
                                    <p
                                    dangerouslySetInnerHTML={{
                                        __html: renderContentWithLinks(tip.content)}}
                                        onClick={(e) => {
                                        const href = e.target.getAttribute('href');
                                        if (href && href.includes('youtube.com')) {
                                            e.preventDefault();
                                            openVideoModal(href);
                                        }
                                        }}
                                    ></p>
                                </div>
                            ))}

                            {/* Show "Scroll down to see more videos" message and loader if more tips are loading */}
                            {isLoadingTips && hasMoreTips && (
                                <div className="scroll-message">
                                    Scroll down to see more videos
                                </div>
                            )}
                            {isLoadingTips && <div className="loader"></div>}

                            {selectedVideo && (
                                <Modal
                                    title="Video"
                                    content={
                                    <iframe
                                        width="100%"
                                        height="315"
                                        src={getYouTubeEmbedUrl(selectedVideo)}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        title="YouTube Video"
                                    ></iframe>
                                    }
                                    onClose={closeModal}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LeadershipProgram;
